import React, { ReactElement } from 'react';
import {
  ApolloClient, 
  InMemoryCache,
  createHttpLink,
  ApolloProvider
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import App from './App';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${process.env.REACT_APP_GRAPHQL_TOKEN}`
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

function AppContainer(): ReactElement {
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
}

export default AppContainer;
