export const USER_STATUS = {
  student: 'github-students',
  faculty: 'github-teachers'
};

export const USER_LINKS = {
  'github-students': 'student',
  'github-teachers': 'educator'
};

export const PACK_LINKS = {
  'github-students': 'GitHub Student Pack',
  'github-teachers': 'GitHub Teacher Toolbox'
};

export const USER_MAP = {
  student: 'student',
  faculty: 'teacher',
  not_faculty: 'student',
  not_student: 'teacher'
};

//prettier-ignore
export const GITHUB_MESSAGES = {
  ARDUINO_AUTH(role, link) {
    const userRoleByLink = USER_LINKS[link];
    const userRoleByRole = USER_MAP[role];

    if (userRoleByRole) {
      return {
        status: true,
        title: `Great, your GitHub ${userRoleByRole} account has been validated!`,
        message: 'Now sign in with Arduino to redeem your free subscription and discount codes.',
        button: 'AUTH_BUTTON',
      };
    }
    
    return {
      title: 'Account not valid',
      message: `This is not a valid GitHub ${userRoleByLink} account. This might be because you have not applied for the ${PACK_LINKS[link]}, or your application has not yet been approved. <br><br> You can apply for the ${PACK_LINKS[link]}, or sign out and try a different GitHub account.`,
      button: `ARDUINO_BUTTON_${userRoleByLink.toUpperCase()}`
    };
  },

  ARDUINO_MODEL(role) {
    if(USER_MAP[role]) {
        return {
            title: `You’re not a ${USER_MAP[`not_${role}`]}!`,
            message: `You have a GitHub  ${USER_MAP[role]} account. To checkout the offers dedicated to you and get your discount codes, visit our page for ${USER_MAP[role]}s.`,
            button: `REDIRECT_${USER_MAP[role].toUpperCase()}_BUTTON`
        };
    }
  },
  GITHUB_TOKEN: {
    title: 'You are not authorized',
    message: 'First sign in with GitHub to redeem your free subscription and discount codes.',
    button: 'GITHUB_BUTTON',
  },
  ARDUINO_AUTH_WITH_GITHUB_TOKEN: {
    title: 'Sorry, you have not completed authorization',
    message: 'Now sign in with Arduino to redeem your free subscription and discount codes.',
    button: 'AUTH_BUTTON',
  },
  ALL_SET: {
    status: true,
    title: 'Awesome, you’re all set!',
    message: 'You will be able to get your discount codes now and enjoy free Arduino Create Maker plan and discounts on our hardware.',
    button: 'DONE',
  },
};
