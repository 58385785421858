import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { fetchGitHubCredentials, getBenefits } from '@api/github';

import actions from './actions';
import types from './types';

import { setCookie, getCookie } from '@utils/cookies';
import { USER_STATUS, GITHUB_MESSAGES } from '@utils/constants';

const pathname = () => {
  return window.location.pathname.replace(
    `/${process.env.REACT_APP_BASE_URL}/`,
    ''
  );
};

const clearQueryParams = () => {
  window.history.pushState({}, document.title, window.location.pathname);
};

const login = function* () {
  yield put(actions.loginRequested());
  try {
    const profile = yield call(window.auth.getProfile);
    if (typeof profile === 'object' && Object.keys(profile).length) {
      yield put(actions.loginSucceeded(profile));
      const benefits = yield call(getBenefits);
      const reselectedBenefits = benefits.reduce((result, elem) => {
        const { product_id, ...item } = elem;
        result[product_id] = item;
        return result;
      }, {});
      yield put(actions.getBenefits(reselectedBenefits));
      yield put(actions.getGithubInit({ profile }));
    } else {
      yield put(actions.loginFailed());
      yield put(actions.getGithubInit({}));
    }
  } catch (error) {
    yield put(actions.loginFailed(error));
    yield put(actions.getGithubInit({ error }));
  }
};

const watchLogin = function* () {
  yield takeEvery(types.LOGIN, login);
};

// GITHUB

const getGitHubToken = function* (data) {
  try {
    const token = getCookie('github_id');
    const user_status = getCookie('user_status');

    if (token && user_status) {
      yield put(
        actions.getGitHubSucceeded({
          token,
          user_status
        })
      );
      if (pathname() !== USER_STATUS[user_status]) {
        yield put(
          actions.setGitHubMessage(
            GITHUB_MESSAGES['ARDUINO_MODEL'](user_status)
          )
        );
      }
      clearQueryParams();
      return;
    }

    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    const state = query.get('state');

    if (!code || !state) {
      return;
    }

    yield put(actions.getGitHubRequested());
    const response = yield call(fetchGitHubCredentials, state, code);

    if (response.message) {
      clearQueryParams();
      yield put(
        actions.setGitHubMessage({
          button: 'ARDUINO_BUTTON',
          ...response
        })
      );
      return;
    }

    if (response.token) {
      clearQueryParams();
      if (response.user_status && response.user_status !== 'other') {
        if (pathname() !== USER_STATUS[response.user_status]) {
          yield put(
            actions.setGitHubMessage(
              GITHUB_MESSAGES['ARDUINO_MODEL'](response.user_status)
            )
          );
          return;
        }

        setCookie('user_status', response.user_status);
        setCookie('github_id', response.token);
        yield put(actions.getGitHubSucceeded(response));
      }

      if (!data.profile || response.user_status === 'other') {
        yield put(
          actions.setGitHubMessage(
            GITHUB_MESSAGES['ARDUINO_AUTH'](response.user_status, pathname())
          )
        );
        return;
      }
      yield put(actions.setGitHubMessage(GITHUB_MESSAGES['ALL_SET']));
    }
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    yield put(actions.setGitHubMessage());
  }
};

const getGitHubTokenFork = function* ({ data }) {
  yield fork(getGitHubToken, data);
};

const watchGitHubToken = function* () {
  yield takeEvery(types.GET_GITHUB, getGitHubTokenFork);
};

export default [watchLogin, watchGitHubToken];
