import React, { useEffect, Suspense, useState, ReactElement } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom';

import createStore from '@store/index';

import Loader from '@components/common/Loader/Loader';
import { setCookie } from '@utils/cookies';

const headersButtons = {
  register: {
    label: 'Register kit',
    url: `${process.env.REACT_APP_KIT_REGISTRATION}`,
    main: true,
    blank: true
  }
};

const Page = React.lazy(() => import('@views/Page/PageContainer'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const store = createStore();

function clearGitHubData() {
  setCookie('github_id', '');
  setCookie('user_status', '');
}

function App(): ReactElement {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.auth.isAuthenticated().then((res: boolean) => {
      if (res) {
        document.getElementById('header')?.classList.add('with-buttons');
        return (window as any).header?.renderMenuButtons([
          headersButtons.register
        ]);
      } else {
        (window as any).header?.renderMenuButtons([headersButtons.register]);
      }
    });

    setLoading(false);
    const logout = Array.from(document.querySelectorAll('.logout'));
    logout.forEach((item) => {
      item.addEventListener('click', clearGitHubData);
    });
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter basename={`/${process.env.REACT_APP_BASE_URL}`}>
        <ScrollToTop />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route
              exact
              path="/contact-us"
              render={() => {
                window.location.href = 'https://www.arduino.cc/en/contact-us/';
                return null;
              }}
            />
            <Route exact path="/:slug?" component={Page} />
            <Route render={() => <Redirect to={`/`} />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
