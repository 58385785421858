const LOGIN = 'app/authentication/LOGIN';
const LOGIN_REQUESTED = 'app/authentication/LOGIN_REQUESTED';
const LOGIN_SUCCEEDED = 'app/authentication/LOGIN_SUCCEEDED';
const LOGIN_FAILED = 'app/authentication/LOGIN_FAILED';

const GET_GITHUB = 'app/github/GET_GITHUB';
const GET_GITHUB_REQUESTED = 'app/github/GET_GITHUB_REQUESTED';
const GET_GITHUB_SUCCEEDED = 'app/github/GET_GITHUB_SUCCEEDED';
const GITHUB_SET_MESSAGE = 'app/github/GITHUB_SET_MESSAGE';
const GITHUB_GET_BENEFITS = 'app/github/GITHUB_GET_BENEFITS';
const GITHUB_UPDATE_BENEFITS = 'app/github/GITHUB_UPDATE_BENEFITS';
const GITHUB_CLEAR_MESSAGE = 'app/github/GITHUB_CLEAR_MESSAGE';
const GITHUB_SHOW_COUPON = 'app/github/GITHUB_SHOW_COUPON';
const GITHUB_HIDE_COUPON = 'app/github/GITHUB_HIDE_COUPON';

export default {
  LOGIN,
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,

  GET_GITHUB,
  GET_GITHUB_REQUESTED,
  GET_GITHUB_SUCCEEDED,
  GITHUB_SET_MESSAGE,
  GITHUB_GET_BENEFITS,
  GITHUB_UPDATE_BENEFITS,
  GITHUB_CLEAR_MESSAGE,
  GITHUB_SHOW_COUPON,
  GITHUB_HIDE_COUPON,
};
