export default function authFetch(input, init) {
  let patchedInit = init;

  if (!init || typeof init !== 'object') {
    patchedInit = {};
  }

  return window.auth.getAccessToken().then((authToken) => {
    const patchedHeaders = Object.assign(patchedInit.headers || {}, {
      Authorization: `Bearer ${authToken}`
    });

    patchedInit = Object.assign(patchedInit, {
      headers: patchedHeaders
    });

    return fetch(input, patchedInit);
  });
}
