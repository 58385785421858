import types from './types';

const initialState = {
  login: {
    profile: {},
    isProfile: false,
    inProgress: false,
    didInvalidate: false,
    lastUpdate: null,
    loggedIn: false,
    error: null,
  },
  github: {
    data: {},
    benefits: {},
    inProgress: false,
    lastUpdate: null,
    message: {},
    storeURL: '',
    coupon: '',
  },
};

// prettier-ignore
export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUESTED:
      return {
        ...state,
        login: {
          ...state.login,
          inProgress: true,
          loggedIn: false,
        },
      };
    case types.LOGIN_SUCCEEDED:
      return {
        ...state,
        login: {
          ...state.login,
          inProgress: false,
          loggedIn: true,
          lastUpdate: Date.now(),
          profile: action.profile,
          isProfile: !!Object.entries(action.profile).length,
        },
      };
    case types.LOGIN_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          inProgress: false,
          loggedIn: false,
          lastUpdate: Date.now(),
          error: action.error,
        },
      };
    case types.GET_GITHUB_REQUESTED:
      return {
        ...state,
        github: {
          ...state.github,
          inProgress: true,
        },
      };
    case types.GET_GITHUB_SUCCEEDED:
      return {
        ...state,
        github: {
          ...state.github,
          data: action.data || {},
          lastUpdate: Date.now(),
          inProgress: false,
        },
      };
    case types.GITHUB_GET_BENEFITS:
      return {
        ...state,
        github: {
          ...state.github,
          benefits: action.data,
          lastUpdate: Date.now(),
        },
      };
    case types.GITHUB_UPDATE_BENEFITS:
      return {
        ...state,
        github: {
          ...state.github,
          benefits: {
            ...state.github.benefits,
            ...action.data,
          },
          lastUpdate: Date.now(),
        },
      };
    case types.GITHUB_SET_MESSAGE:
      return {
        ...state,
        github: {
          ...state.github,
          message: action.message,
          lastUpdate: Date.now(),
          inProgress: false,
        },
      };

    case types.GITHUB_CLEAR_MESSAGE:
      return {
        ...state,
        github: {
          ...state.github,
          message: {
            ...state.github.message,
            message: '',
          },
          lastUpdate: Date.now(),
          inProgress: false,
        },
      };
    case types.GITHUB_SHOW_COUPON:
      return {
        ...state,
        github: {
          ...state.github,
          ...action.data,
        },
      };

    case types.GITHUB_HIDE_COUPON:
      return {
        ...state,
        github: {
          ...state.github,
          storeURL: '',
          coupon: ''
        },
      };
    default:
      return state;
  }
}
