import { getCookie } from '@utils/cookies';
import authenticatedFetch from '@utils/authFetch';

export function fetchGitHubCredentials(state, code) {
  const url = `${process.env.REACT_APP_GITHUB_API}/v1/login/oauth/access_token?state=${state}&code=${code}`;

  return fetch(url, {
    mode: 'cors',
    method: 'POST',
  })
    .then(function (response) {
      return response.json() || {};
    })
    .catch((error) => {
      return error || { message: 'Unknown error :(' };
    });
}

export async function redeemCodeRequest(name) {
  const url = `${process.env.REACT_APP_GITHUB_API}/v1/coupon`;
  const token = getCookie('github_id');
  const response = await authenticatedFetch(url, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      product: name,
      token_id: token,
    }),
  });

  const data = await response.json();
  return data;
}

export async function getBenefits() {
  const url = `${process.env.REACT_APP_GITHUB_API}/v1/benefit`;
  const response = await authenticatedFetch(url);

  const data = await response.json();
  return data || [];
}
