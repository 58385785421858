import types from './types';

export const login = (redirect) => ({
  type: types.LOGIN,
  redirect,
});

export const loginRequested = () => ({
  type: types.LOGIN_REQUESTED,
});

export const loginSucceeded = (profile) => ({
  type: types.LOGIN_SUCCEEDED,
  profile,
});

export const loginFailed = (error) => ({
  type: types.LOGIN_FAILED,
  error,
});

export const getGithubInit = (data) => ({
  type: types.GET_GITHUB,
  data,
});

export const getGitHubRequested = () => ({
  type: types.GET_GITHUB_REQUESTED,
});

export const getGitHubSucceeded = (data) => ({
  type: types.GET_GITHUB_SUCCEEDED,
  data,
});

export const getBenefits = (data) => ({
  type: types.GITHUB_GET_BENEFITS,
  data,
});

export const updateBenefits = (data) => ({
  type: types.GITHUB_UPDATE_BENEFITS,
  data,
});

export const setGitHubMessage = (message) => ({
  type: types.GITHUB_SET_MESSAGE,
  message,
});

export const clearGitHubMessage = () => ({
  type: types.GITHUB_CLEAR_MESSAGE,
});

export const showCoupon = (data) => ({
  type: types.GITHUB_SHOW_COUPON,
  data,
});

export const hideCoupon = () => ({
  type: types.GITHUB_HIDE_COUPON,
});

export default {
  login,
  loginRequested,
  loginSucceeded,
  loginFailed,
  getGitHubRequested,
  getGitHubSucceeded,
  setGitHubMessage,
  getGithubInit,
  getBenefits,
  showCoupon,
  hideCoupon,
};
